<template>
  <v-card
    width="442"
    elevation="0"
    color="transparent"
    dark
    style="position: unset; padding-top: 142px; padding-left: 102px;"
  >
    <h3 class="step-1-title mb-6">
      <span v-if="edit">{{ $t('page.dashboards.edit_dashboard_title') }}</span>
      <span v-else>{{ $t('page.dashboards.add_dashboard_title') }}</span>
    </h3>
    <v-form
      ref="form"
      @submit.prevent
    >
      <v-text-field
        v-model.trim="title"
        class="mb-2"
        :placeholder="$t('page.dashboards.placeholder_dashboard_name')"
        :rules="rulesTitle"
        solo
        flat
        light
      />
      <v-textarea
        v-model="description"
        class="mb-4"
        :placeholder="$t('page.dashboards.placeholder_dashboard_description')"
        solo
        flat
        light
        hide-details
      />
    </v-form>

    <!-- <p class="pl-4">
      請依照步驟建立儀表板<span style="vertical-align: sub;">。</span>
    </p> -->

    <div class="logo-img" />
  </v-card>
</template>

<script>
import { validateRequired, validateDuplicate } from '@/assets/js/validate'

import { mapGetters, mapState } from 'vuex'

const rulesTitle = vm => [
  validateRequired,
  validateDuplicate(vm.dashboardTitles)
]

export default {
  name: 'DashboardAdderStep1',

  computed: {
    ...mapState({
      formData: state => state.dashboards.form.formData,
      dashboards: state => state.dashboards.dashboards,
      currentEditDashboard: state => state.dashboards.form.currentEditDashboard
    }),
    ...mapGetters({
      edit: 'dashboards/form/editModel'
    }),
    rulesTitle,

    dashboardTitles() {
      const titles = this.dashboards.map(d => d.title)

      if (this.currentEditDashboard) {
        return titles.filter(title => title !== this.currentEditDashboard.title)
      }
      return titles
    },
    title: {
      get() {
        return this.formData.title
      },
      set(newVal) {
        this.$store.dispatch('dashboards/form/setFormData', {
          title: newVal
        })
      }
    },
    description: {
      get() {
        return this.formData.description
      },
      set(newVal) {
        this.$store.dispatch('dashboards/form/setFormData', {
          description: newVal
        })
      }
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.step-1-title {
  font-size: 2.25rem;
  font-weight: normal;
}

.logo-img {
  position: absolute;
  right: 92px;
  bottom: 36px;
  width: 208px;
  height: 112px;
  background-size: cover;
  opacity: 0.35;
  background-image: url("../../assets/images/logo.svg");
}
</style>
