<template>
  <v-card
    class="file-info-card"
    outlined
    height="100%"
    style="overflow: auto;"
  >
    <template v-if="showFileNode">
      <v-row
        v-if="activeFileNode.is3DFile"
        no-gutters
      >
        <v-col
          class="ml-auto"
          cols="auto"
        >
          <FileMenu
            :readonly="readonly"
            :file-node="activeFileNode"
          />
        </v-col>
      </v-row>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ showFileNode.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="mb-4"
        style="min-height: unset;"
      >
        <v-list-item-content class="pa-0">
          <v-list-item-subtitle
            class="text-body-2"
            :title="showFileNode.uuid"
          >
            {{ showFileNode.uuid }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn
            :title="$t('copy_uuid')"
            icon
            x-small
            @click="copy(showFileNode.uuid)"
          >
            <v-icon>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{ $t('file_type') }}</v-list-item-title>
          <v-list-item-subtitle>{{ formatFileType(showFileNode) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{ $t('update_date') }}</v-list-item-title>
          <v-list-item-subtitle>{{ formatDateTime(showFileNode.modifiedTime) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{ $t('create_date') }}</v-list-item-title>
          <v-list-item-subtitle>{{ formatDateTime(showFileNode.createdTime) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-card>
</template>

<script>
import FileMenu from '../Functions/FileMenu'

import { FILE_TYPE } from '@/models/utils'

import { mapState } from 'vuex'

import copyText from 'clipboard-copy'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'FileInfo',

  components: {
    FileMenu
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    FILE_TYPE
  }),

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      activeFileNode: state => state.files.activeFileNode
    }),

    showFileNode() {
      const activeFileNode = this.activeFileNode

      if (!activeFileNode || activeFileNode === this.fileTree.root) {
        return null
      }

      return activeFileNode
    }
  },

  methods: {
    copy(text) {
      return copyText(text)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_copied')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    },
    formatDateTime(utc) {
      const dateTime = dayjs(utc)
      if (!dateTime.isValid()) {
        return ''
      }

      return dateTime.local().format('YYYY/MM/DD HH:mm')
    },
    formatFileType(fileNode) {
      switch (fileNode.type) {
        case FILE_TYPE.FOLDER:
          return this.$t('page.files.folder')
        case FILE_TYPE.MAPSET:
          return this.$t('page.files.mapset')
        case FILE_TYPE.FILE:
          return this.$t('page.files.file', {
            fileType: `${fileNode.fileType?.toUpperCase?.()} `
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-info-card {
  .v-list-item__title {
    font-size: 1rem;
    font-weight: bold;
  }

  .v-list-item__subtitle {
    font-size: 1rem;
  }

  .v-list-item .v-list-item__title,
  .v-list-item .v-list-item__subtitle {
    line-height: 1.4;
  }
}
</style>
