var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-3",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"font-weight-black",staticStyle:{"font-size":"1.375rem"},attrs:{"text":"","color":"primary","to":{ name: 'Projects' },"exact":""}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.project.name))])],1)],1),_c('v-spacer'),(_vm.isProjectOwner || _vm.roleGrafana.create)?_c('v-col',{attrs:{"cols":"auto"}},[_c('DashboardAdder',{on:{"successAdded":function($event){return _vm.initialize(true)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-add-dashboard",attrs:{"color":"primary","disabled":_vm.disableActionsBtn}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('page.dashboards.add_dashboard_title'))+" ")])]}}],null,false,2668589007),model:{value:(_vm.dialogDashboard),callback:function ($$v) {_vm.dialogDashboard=$$v},expression:"dialogDashboard"}})],1):_vm._e()],1),(_vm.error)?_c('IssueCard',{staticClass:"mt-13",attrs:{"message":_vm.$t('api_errors.data_error_refresh_page'),"error":""}}):_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"dashboard-table",staticStyle:{"width":"100%"},attrs:{"headers":_vm.tableHeaders,"items":_vm.dashboards,"items-per-page":-1,"search":_vm.search,"item-class":function () { return 'dashboard-tr'; },"loading":_vm.isLoading,"no-data-text":_vm.$t('page.dashboards.no_data_and_create_dashboard'),"loading-text":((_vm.$t('loading')) + "..."),"fixed-header":"","border":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var dashboard = ref.item;
return [(_vm.disableActionsBtn)?_c('span',[_vm._v(_vm._s(dashboard.title))]):_c('router-link',{attrs:{"title":dashboard.title,"to":_vm.toDashboard(dashboard),"disabled":_vm.disableActionsBtn}},[_vm._v(" "+_vm._s(dashboard.title)+" ")])]}},{key:"item.actions",fn:function(ref){
var dashboard = ref.item;
return [(_vm.isProjectOwner || _vm.roleDashboard(dashboard).update)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.disableActionsBtn},on:{"click":function($event){return _vm.editDashboard(dashboard)}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(_vm.isProjectOwner || _vm.roleDashboard(dashboard).delete)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.disableActionsBtn}},[_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.deleteDashboard(dashboard)}}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}},{key:"item.comment",fn:function(ref){
var dashboard = ref.item;
return [_c('span',{attrs:{"title":dashboard.comment}},[_vm._v(_vm._s(dashboard.comment))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }