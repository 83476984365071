<template>
  <div
    class="upload-file-item"
  >
    <v-progress-linear
      height="1"
      buffer-value="0"
      :stream="isUploading"
      :value="progress"
      :success="success"
    />
    <v-form
      ref="form"
      v-model="isValid"
      @submit.prevent
    >
      <v-input
        :value="file"
        class="content"
        :success="success"
        :rules="rulesFile"
        :error="!!errorMessage"
        :error-messages="errorMessage"
        hide-details="auto"
      >
        <v-row
          no-gutters
          align="center"
        >
          <v-col cols="auto">
            {{ text||file.name }}
          </v-col>
          <v-col
            v-if="isUploading"
            cols="auto"
          >
            &nbsp;({{ progress }}%)
          </v-col>
          <v-col
            v-else-if="success"
          >
            <v-row
              no-gutters
              align="center"
            >
              &nbsp;{{ $t('success_uploaded') }}
              <v-icon
                right
                color="success"
                small
              >
                mdi-check
              </v-icon>
            </v-row>
          </v-col>

          <v-spacer />
          <SettingsDialog
            v-if="
              fileNode &&
                fileNode.is3DFile
            "
            :file-node="fileNode"
            is-new-file
            :readonly="isUploading||queuing||success"
            style="pointer-events: auto;"
          />
          <v-col
            class="ml-4"
            cols="auto"
          >
            <v-btn
              v-if="isUploading"
              icon
              small
              style="pointer-events: auto;"
              @click.stop="cancel"
            >
              <v-icon small>
                mdi-stop
              </v-icon>
            </v-btn>
            <v-btn
              v-else-if="!queuing"
              color="error"
              icon
              small
              style="pointer-events: auto;"
              @click.stop="remove"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-input>
    </v-form>
  </div>
</template>

<script>
import SettingsDialog from '../Settings/SettingsDialog'

import { FileNode } from '@/models'
import { FILE_TYPE } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'
import { validateFileType } from '@/assets/js/validate'

export default {
  name: 'UploadFileItem',

  components: {
    SettingsDialog
  },

  props: {
    parent: {
      // parent只能是mapset才能增加檔案
      type: FileNode,
      default: null
    },
    file: {
      type: File,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    queuing: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isValid: true,
    progress: null,
    isUploading: false,
    errorMessage: undefined,
    success: false
  }),

  computed: {
    ...mapState({
      asseptFileType: state => state.files.asseptFileType
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    },
    fileNode() {
      if (!this.file) {
        return
      }

      return new FileNode('temp', {
        type: FILE_TYPE.FILE,
        name: this.file.name,
        size: this.file.size,
        modifiedTime: this.file?.lastModifiedDate?.toISOString()
      })
    },
    rulesFile() {
      return [
        validateFileType(
          this.asseptFileType.split(',').map(type =>
            type
              .trim()
              .replaceAll('.', '')
              .toLowerCase()
          )
        )
      ]
    }
  },

  mounted() {
    this.validate()
  },

  methods: {
    init() {
      this.isUploading = false
      this.progress = null
    },
    validate() {
      return this.$refs.form.validate()
    },
    cancel() {
      // cancelUpload是利用axios添加上的function
      if (this.file.cancelUpload && this.isUploading) {
        this.file.cancelUpload(this.$t('cancel_uploaded'))
        this.init()
      }
    },
    remove() {
      this.$emit('remove')
    },
    onUploadProgress(event) {
      this.progress = Math.round((100 * event.loaded) / event.total)
    },
    async submit() {
      this.errorMessage = undefined

      await this.validate()

      if (!this.isValid) {
        return
      }

      this.isUploading = true
      this.progress = 0
      return this.$store
        .dispatch('files/uploadFile', {
          parent: this.parent,
          file: this.file,
          settings: this.fileNode.settings,
          project: this.project,
          onUploadProgress: this.onUploadProgress
        })
        .then(() => {
          this.success = true
        })
        .catch(error => {
          this.errorMessage = String(error)

          return Promise.reject(error)
        })
        .finally(() => {
          this.init()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-file-item {
  width: 100%;

  & + .upload-file-item {
    border-top: 1px solid $color-secondary;
  }

  .content {
    padding: 4px;
    font-size: 0.9rem;
  }
}
</style>
