var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"overflow-y":"auto","border":"none"},attrs:{"loading":_vm.isLoading === true,"outlined":""}},[_c('v-btn',{staticStyle:{"position":"absolute","top":"8px","right":"12px","z-index":"1"},attrs:{"color":"primary","disabled":_vm.isLoading===true||
        (_vm.root && _vm.root.isLoading===true),"icon":"","small":""},on:{"click":function($event){return _vm.refreshChildren(_vm.selectedFileNode||_vm.root)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-refresh ")])],1),(typeof _vm.isLoading === 'string')?_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('IssueCard',{staticStyle:{"width":"100%"},attrs:{"error":"","img-width":"40%","message":_vm.isLoading},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center mb-4",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.isLoading)+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"text-decoration-underline",attrs:{"color":"primary","text":"","loading":_vm.isLoading===true},on:{"click":function($event){return _vm.selectFileNode(_vm.selectedFileNode)}}},[_vm._v(" "+_vm._s(_vm.$t('reload'))+" ")])],1)],1)]},proxy:true}],null,false,2492748790)})],1):(!_vm.hasChildrenNodes && _vm.isLoading === false)?_c('FileIssueCard',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[(_vm.selectedFileNode.type===_vm.FILE_TYPE.FOLDER)?_c('span',[(
              _vm.readonly || !_vm.roleS3SelectedFileNode.create
            )?_c('span',[_vm._v(_vm._s(_vm.$t('page.files.no_mapset')))]):_c('span',[_vm._v(_vm._s(_vm.$t('page.files.no_mapset_and_create')))])]):(_vm.selectedFileNode.type===_vm.FILE_TYPE.MAPSET)?_c('span',[(
              _vm.readonly || !_vm.roleS3SelectedFileNode.create
            )?_c('span',[_vm._v(_vm._s(_vm.$t('page.files.no_file')))]):_c('span',[_vm._v(_vm._s(_vm.$t('page.files.no_file_and_upload')))])]):_vm._e()]),(!_vm.$route.params.projectId || _vm.roleS3SelectedFileNode.create)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[(_vm.readonly && !_vm.project)?_c('v-btn',{attrs:{"color":"primary","to":{name:'FileMgr'}}},[(
              !_vm.selectedFileNode ||
                _vm.selectedFileNode.type === _vm.FILE_TYPE.FOLDER
            )?_c('span',[_vm._v(_vm._s(_vm.$t('go_to_create')))]):(
              _vm.selectedFileNode &&
                _vm.selectedFileNode.type===_vm.FILE_TYPE.MAPSET
            )?_c('span',[_vm._v(_vm._s(_vm.$t('go_to_upload')))]):_vm._e(),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" mdi-arrow-right ")])],1):(!_vm.selectedFileNode ||
            _vm.selectedFileNode.type === _vm.FILE_TYPE.FOLDER
          )?_c('AddFolderDialog',{attrs:{"parent":_vm.selectedFileNode}}):(
            _vm.selectedFileNode &&
              _vm.selectedFileNode.type===_vm.FILE_TYPE.MAPSET
          )?_c('UploadMultiFilesDialog',{attrs:{"parent":_vm.selectedFileNode}}):_vm._e()],1):_vm._e()],1)],1):_c('v-list',{staticClass:"file-list mt-8",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.activeFileNode),callback:function ($$v) {_vm.activeFileNode=$$v},expression:"activeFileNode"}},[_vm._l((_vm.childrenNodes),function(childrenNode,iChildrenNode){return [_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(iChildrenNode),expression:"iChildrenNode"}],key:((childrenNode.uuid) + "-" + iChildrenNode),staticClass:"px-5",attrs:{"value":childrenNode,"ripple":false},nativeOn:{"dblclick":function($event){childrenNode.type === _vm.FILE_TYPE.FILE
              ? _vm.fetchFileData(childrenNode)
              : _vm.selectFileNode(childrenNode)}}},[_c('v-list-item-icon',{staticClass:"mr-5 align-center"},[(childrenNode.type !== _vm.FILE_TYPE.FILE)?_c('v-btn',{attrs:{"color":_vm.isFileNodeJoined(childrenNode) ? 'secondary': 'primary',"icon":""},on:{"click":function($event){return _vm.selectFileNode(childrenNode)}}},[_c('v-icon',[(
                    _vm.isFileNodeJoined(childrenNode) &&
                      childrenNode.type===_vm.FILE_TYPE.FOLDER
                  )?[_vm._v(" $vuetify.icons.joined-folder ")]:[_vm._v(" $vuetify.icons.enter-folder ")]],2)],1):_c('v-icon',{class:{'spin': childrenNode.isUploading},attrs:{"color":_vm.isFileNodeJoined(childrenNode)
                  ? 'secondary'
                  : childrenNode.isDamagedFile
                    ? 'error'
                    :'primary'}},[_vm._v(" "+_vm._s(childrenNode.isDamagedFile ?'$vuetify.icons.file-error' : childrenNode.isUploading ? '$vuetify.icons.file-loading' : childrenNode.maplayerType === 'SETTINGS_FILE' ? '$vuetify.icons.settings-file' : _vm.$vuetify.icons.values[childrenNode.fileType] ? ("$vuetify.icons." + (childrenNode.fileType)) : '$vuetify.icons.unknown')+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center","title":childrenNode.name}},[(
                    _vm.isProjectModel &&
                      childrenNode.type!==_vm.FILE_TYPE.FOLDER &&
                      _vm.isFileNodeJoined(childrenNode) &&
                      !_vm.hasResource(childrenNode.uuid)
                  )?_c('v-col',{staticClass:"mr-1",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.files.not_yet_join_project')))])])],1):_vm._e(),(
                    _vm.isFileNodeJoined(childrenNode) &&
                      (childrenNode.type===_vm.FILE_TYPE.FOLDER || _vm.isDashboardModel)
                  )?_c('v-col',{staticClass:"mr-2",attrs:{"cols":"auto"}},[_vm._v(" ("+_vm._s(_vm.$t('joined'))+") ")]):_vm._e(),_c('v-col',{staticClass:"text-truncate"},[(childrenNode.type===_vm.FILE_TYPE.FILE)?_c('span',[_vm._v(_vm._s(childrenNode.name))]):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('a',{class:{
                        'text-decoration-underline':hover
                      },on:{"click":function($event){return _vm.selectFileNode(childrenNode)}}},[_vm._v(_vm._s(childrenNode.name))])]}}],null,true)})],1)],1)],1)],1),(
              !_vm.readonly &&
                childrenNode.type === _vm.FILE_TYPE.FILE
            )?_c('v-list-item-action',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(
                  childrenNode.type === _vm.FILE_TYPE.FILE &&
                    (childrenNode.isLoading || childrenNode.hasLoaded)
                )?_c('v-col',{staticClass:"d-flex align-center mr-4",staticStyle:{"font-size":"0.6em"},attrs:{"cols":"auto"}},[(
                    childrenNode.isLoading===true
                  )?_c('v-progress-circular',{staticClass:"mr-6",attrs:{"color":"grey","width":"2","size":"20","indeterminate":""}}):_c('v-row',{class:{
                    'secondary--text': !childrenNode.isLoading,
                    'error--text': typeof childrenNode.isLoading === 'string'
                  },staticStyle:{"min-width":"8em"},attrs:{"no-gutters":"","align":"center"}},[_c('v-icon',{attrs:{"color":typeof childrenNode.isLoading === 'string'
                        ? 'error'
                        : 'secondary',"small":""}},[_vm._v(" "+_vm._s(typeof childrenNode.isLoading === 'string' ? 'mdi-close' : 'mdi-check')+" ")]),_vm._v(" "+_vm._s(typeof childrenNode.isLoading === 'string' ? childrenNode.isLoading : _vm.$t('layer_loaded'))+" ")],1)],1):_vm._e(),_c('v-col',{directives:[{name:"visible",rawName:"v-visible",value:(!childrenNode.isUploading),expression:"!childrenNode.isUploading"}],attrs:{"cols":"auto"}},[(!childrenNode.downloadProgress)?_c('v-btn',{attrs:{"icon":"","disabled":childrenNode.isDownloading,"loading":childrenNode.isDownloading},on:{"click":function($event){return _vm.downloadFile(childrenNode)}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_c('span',[_vm._v(_vm._s(childrenNode.downloadProgress)+"%")])],1)],1)],1):_vm._e()],1),(iChildrenNode)?_c('v-divider',{key:((childrenNode.uuid || _vm.iChildrenNodes) + "-divider")}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }