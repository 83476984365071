import { render, staticRenderFns } from "./UploadMultiFilesDialog.vue?vue&type=template&id=583d7128&scoped=true&"
import script from "./UploadMultiFilesDialog.vue?vue&type=script&lang=js&"
export * from "./UploadMultiFilesDialog.vue?vue&type=script&lang=js&"
import style0 from "./UploadMultiFilesDialog.vue?vue&type=style&index=0&id=583d7128&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583d7128",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VDialog,VFileInput,VRow,VSpacer})
