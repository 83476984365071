<template>
  <v-card
    color="transparent"
    elevation="0"
  >
    <v-card-title
      class="justify-center"
      style="margin-bottom: 7.5vh;"
    >
      <slot name="message">
        <span>{{ message }}</span>
      </slot>
    </v-card-title>
    <v-card-text style="height: calc(100% - 60px - 7.5vh);">
      <v-img
        class="mx-auto"
        :width="imgWidth"
        max-height="100%"
        :src="imgSrc"
        position="top center"
      />
    </v-card-text>
  </v-card>
</template>

<script>
const imgSrcs = {
  noData: require('@/assets/images/no-data.svg'),
  notFound: require('@/assets/images/no-member.svg'),
  error: require('@/assets/images/error-data.svg')
}
export default {
  name: 'IssueCard',

  props: {
    message: {
      type: String,
      default: ''
    },
    imgWidth: {
      type: String,
      default: '422'
    },
    error: {
      type: Boolean,
      default: false
    },
    notFound: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    imgSrc() {
      if (this.error) return imgSrcs.error

      if (this.notFound) return imgSrcs.notFound

      return imgSrcs.noData
    }
  }
}
</script>

<style lang="scss" scoped></style>
