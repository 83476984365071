<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :persistent="isUploading"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      >
        <v-btn
          v-bind="attrs"
          color="primary"
          :disabled="!parent"
          v-on="on"
        >
          {{ $t('page.files.upload_maplayer_title') }}
        </v-btn>
      </slot>
    </template>

    <v-card class="action-dialog-card">
      <v-card-text>
        <v-row
          class="
            draggable
            grey--text
            text--darken-4
            mb-4
          "
          no-gutters
        >
          {{ $t('page.files.upload_maplayer_content') }}
          <span
            class="primary--text"
            style="margin-left: 0.5em;"
          >{{ parent.name }}</span>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-file-input
              ref="fileInput"
              v-model="files"
              class="file-input"
              :placeholder="$t('page.files.placeholder_select_file')"
              :accept="asseptFileType"
              :show-size="1024"
              multiple
              filled
              :clearable="false"
              :disabled="isUploading"
              @change="onChangeFile"
            >
              <!-- <template v-slot:progress>
                  <v-progress-circular
                    :value="progress"
                    :rotate="-90"
                    color="primary"
                    size="80"
                    width="10"
                  >
                    {{ progress }}%
                  </v-progress-circular>
                </template> -->
              <template #selection="{ file, text }">
                <UploadFileItem
                  :key="file.name"
                  :file="file"
                  :text="text"
                  :parent="parent"
                  :queuing="isUploading"
                  @remove="removeFile(file)"
                />
              </template>
            </v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pb-0">
        <v-spacer />
        <v-btn
          class="action-btn"
          color="primary"
          rounded
          :loading="isUploading"
          :disabled="!hasFiles"
          @click="submit"
        >
          {{ $t('upload') }}
        </v-btn>
        <v-btn
          class="action-btn ml-3"
          rounded
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UploadFileItem from './UploadFileItem'

import PQueue from 'p-queue/dist' // esm lib要from 'xxx/dist'

import { mapGetters, mapState } from 'vuex'

import { FileNode } from '@/models'

export default {
  name: 'UploadMultiFilesDialog',

  components: {
    UploadFileItem
  },

  props: {
    parent: {
      // parent只能是mapset才能增加檔案
      type: FileNode,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    isUploading: false,
    files: [],
    queue: null // PQueue instance
  }),

  computed: {
    ...mapState({
      asseptFileType: state => state.files.asseptFileType,
      activeFileNode: state => state.files.activeFileNode,
      selectedFileNode: state => state.files.selectedFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    },

    hasFiles() {
      return Array.isArray(this.files) && !!this.files.length
    }
  },

  mounted() {
    window.addEventListener('beforeunload', this.onBeforeUnload)
  },

  beforeDestroy() {
    this.cancel()
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },

  methods: {
    removeFile(file) {
      this.files = this.files.filter(f => f !== file)
    },
    init() {
      this.files = []
      this.isUploading = false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    close() {
      this.dialog = false
    },
    cancel() {
      if (this.isUploading) {
        const refUploadFileItems = this.$refs.fileInput.$children.filter(
          children => children.file instanceof File
        )
        refUploadFileItems.forEach(ref => ref.cancel())

        this.queue.clear()
      }

      this.init()
      this.close()
    },
    fetchProject() {
      return this.$store.dispatch('projects/fetchProject', this.$route.params)
    },
    onChangeFile(files) {
      if (!Array.isArray(files) || !files.length) {
        this.init()
      }
    },
    onBeforeUnload(event) {
      if (this.isUploading) {
        // Cancel the event as stated by the standard.
        event.preventDefault()
        // Chrome requires returnValue to be set.
        event.returnValue = this.$t('page.files.confirm_leave_page_when_uploading')

        return this.$t('page.files.confirm_leave_page_when_uploading')
      }
    },
    async submit() {
      const refUploadFileItems = this.$refs.fileInput.$children.filter(
        children => (children.file instanceof File) && !children.success
      )

      if (!refUploadFileItems.length) {
        return Promise.resolve()
      }

      return new Promise((resolve, reject) => {
        let error = null
        this.queue = new PQueue({ concurrency: 1 })
        this.queue.on('idle', () => {
          setTimeout(() => {
            if (!this.queue.size && !this.queue.pending) {
              if (error instanceof Error) {
                return reject(error)
              } else {
                resolve()
              }
            }
          }, 1500)
        })
        this.queue.on('error', err => {
          error = err
        })

        this.isUploading = true
        this.queue.addAll(
          refUploadFileItems.map(refUploadFileItem => refUploadFileItem.submit)
        )
      })
        .then(() => {
          this.close()
          this.init()
        })
        .finally(() => {
          if (this.project) {
            this.fetchProject()
          }
          this.$store.dispatch('files/fetchFileNodes', {
            project: this.project,
            parent: this.parent,
            loading: false
          })
          this.isUploading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.action-dialog-card {
  padding: 24px 2rem;

  .v-card__subtitle,
  .v-card__text {
    font-size: 1rem;
  }

  .file-input.v-input {
    ::v-deep {
      .v-input__slot {
        &::before,
        &::after {
          cursor: default;
        }

        .v-text-field__slot {
          cursor: pointer;
        }
      }
    }
  }
}

.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
