<template>
  <v-card
    class="d-flex flex-column"
    color="transparent"
    elevation="0"
    style="height: 100%;"
  >
    <!-- <v-card-title class="justify-center flex-grow-0">
        <span>test123</span>
      </v-card-title> -->
    <v-card-text class="d-flex flex-grow-1 justify-center align-center">
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FileIssueCard'
}
</script>

<style lang="scss" scoped></style>
