<template>
  <v-card
    class="d-flex flex-column"
    :loading="isLoadingFolders"
    outlined
    height="100%"
    style="overflow: auto;"
  >
    <v-card-text
      v-if="!isDashboardModel && roleS3.create"
      class="flex-grow-0"
    >
      <AddFolderDialog>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="primary"
            block
            depressed
            v-on="on"
          >
            {{ $t('page.files.add_folder_title') }}
          </v-btn>
        </template>
      </AddFolderDialog>
      <v-divider class="my-4" />
    </v-card-text>
    <v-card-text
      class="flex-grow-1 pa-0"
      :class="{
        'mt-6': isDashboardModel || !roleS3.create
      }"
      style="overflow-x: auto;"
    >
      <v-treeview
        ref="tree"
        class="file-treeview ml-n2"
        :load-children="fetchChildren"
        :items="folderNodes"
        :open.sync="openNodes"
        :active="activeFileNode"
        :expand-icon="''"
        item-key="uuid"
        open-on-click
        return-object
        hoverable
        dense
      >
        <template #prepend="{ item, open }">
          <v-icon
            v-if="item.type===FILE_TYPE.FILE"
            :color="isFileNodeJoined(item) ? 'secondary' : 'primary'"
            @click="onClickTreeItem(item)"
          >
            {{
              item.maplayerType === 'SETTINGS_FILE'
                ? '$vuetify.icons.settings-file'
                : $vuetify.icons.values[item.fileType]
                  ? `$vuetify.icons.${item.fileType}`
                  : '$vuetify.icons.unknown'
            }}
          </v-icon>
          <v-icon
            v-else-if="
              item.type===FILE_TYPE.FOLDER &&
                isFileNodeJoined(item)
            "
            color="secondary"
            @click="onClickTreeItem(item)"
          >
            $vuetify.icons.joined-folder
          </v-icon>
          <v-icon
            v-else
            :color="isFileNodeJoined(item) ? 'secondary' : 'primary'"
            @click="onClickTreeItem(item)"
          >
            {{
              open
                ? item.children.length
                  ?'$folder-open'
                  :'$folder-open-empty'
                : '$folder'
            }}
          </v-icon>
        </template>
        <template #label="{ item }">
          <v-row
            class="v-treeview-node__label__content flex-nowrap"
            no-gutters
            align="center"
            :title="item.name"
            @click="onClickTreeItem(item)"
          >
            <!-- 編輯專案時針對新增且尚未加入到專案的檔案做提醒 -->
            <v-col
              v-if="
                isProjectModel &&
                  item.type!==FILE_TYPE.FOLDER &&
                  isFileNodeJoined(item) &&
                  !hasResource(item.uuid)
              "
              class="mr-1"
              cols="auto"
            >
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <span>{{ $t('page.files.not_yet_join_project') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="
                isFileNodeJoined(item) &&
                  (item.type===FILE_TYPE.FOLDER || isDashboardModel)
              "
              class="mr-1"
              cols="auto"
            >
              ({{ $t('joined') }})
            </v-col>
            <v-col>{{ item.name }}</v-col>
          </v-row>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import AddFolderDialog from '../Functions/ActionDialogs/AddFolderDialog'

import { FILE_TYPE } from '@/models/utils'
import { roleRoot } from '@/store/modules/files/files'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'FileTree',

  components: {
    AddFolderDialog
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    FILE_TYPE
  }),

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      currentEditProject: state => state.projects.form.currentEditProject
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      getResourceByResourceId: 'projects/getResourceByResourceId',
      isLoadingFolders: 'files/isLoadingFolders',
      folderNodes: 'files/folderNodes',
      isFileNodeJoined: 'files/isFileNodeJoined',
      isProjectModel: 'files/isProjectModel',
      isDashboardModel: 'files/isDashboardModel'
    }),

    openNodes: {
      get() {
        return this.$store.state.files.openNodes
      },
      set(newVal) {
        this.$store.commit('files/setState', {
          openNodes: newVal
        })
      }
    },

    activeFileNode: {
      get() {
        return [this.$store.state.files.activeFileNode]
      },
      set(newVal) {
        const newActiveNode = Array.isArray(newVal) ? newVal.pop() : newVal
        this.$store.dispatch('files/activeFileNode', {
          fileNode: newActiveNode
        })
      }
    },

    selectedFileNode: {
      get() {
        return this.$store.state.files.selectedFileNode
      },
      set(newVal) {
        this.$store.dispatch('files/selectFileNode', {
          fileNode: newVal
        })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    root() {
      return this.fileTree?.root
    },
    roleS3() {
      if (!this.project || this.project.isOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.project.s3BucketId) || {}
    }
  },

  mounted() {
    if (!this.isLoadingFolders) {
      this.fetchChildren()
    }
  },

  beforeDestroy() {
    this.$store.dispatch('files/init')
  },

  methods: {
    hasResource(resourceId) {
      if (!this.currentEditProject || !resourceId) {
        return
      }

      return !!this.getResourceByResourceId(this.currentEditProject, resourceId)
    },
    fetchChildren(parentNode) {
      if (parentNode?.isLoading) {
        return
      }

      // 不指定parentNode就是fetch資料夾
      const parent = parentNode || this.root
      const project = this.project
      return this.$store.dispatch('files/fetchFileNodes', {
        project,
        parent
      })
        .then(() => {
          // setTimeout的que才會壓在vnode loadChildren task後面
          setTimeout(() => {
            if (this.openNodes.indexOf(parentNode) === -1) {
              this.openNodes = [parentNode, ...this.openNodes]
            }
          }, 0)
        })
        .catch(error => this.$store.dispatch('snackbar/showError', {
          content: error
        }))
    },
    async onClickTreeItem(item) {
      // NOTE: Tricky for vuetify treeview reload children
      // [Bug Report] v-treeview: "activatable" does not work with "open-on-click" #5947
      // https://github.com/vuetifyjs/vuetify/issues/5947

      if (item.type === FILE_TYPE.FILE) {
        this.activeFileNode = item
        this.selectedFileNode = item.parent

        return
      }

      // setTimeout的que才會壓在vnode loadChildren task後面
      setTimeout(() => {
        const vnode = this.$refs.tree.nodes[item.uuid]?.vnode

        // NOTE: 參考 Vuetify VTreeviewNode.ts methods 167行 checkChildren() 實作
        if (vnode?.isOpen) {
          if (vnode?.hasLoaded || !item.children.length) {
            vnode.isLoading = true
            vnode.loadChildren(vnode.item)
              .finally(() => {
                vnode.isLoading = false
              })
          }
        }
      }, 0)
      this.activeFileNode = item
      this.selectedFileNode = item
    }
  }
}
</script>

<style lang="scss" scoped>
$tree-item-height: 30px;

.file-treeview.v-treeview {
  width: fit-content;
  min-width: calc(100% + 8px);

  ::v-deep .v-treeview-node__root {
    min-height: $tree-item-height;
  }

  .v-treeview-node__label__content {
    width: 100%;
    min-height: $tree-item-height;
  }
}
</style>
