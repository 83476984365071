<template>
  <v-dialog
    v-model="dialog"
    max-width="1200"
    :persistent="isSending"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>

    <v-card height="90vh">
      <v-stepper
        v-model="step"
        class="dashboard-adder-stepper"
      >
        <v-stepper-items style="position: relative;">
          <v-stepper-content
            class="stepper-content bg-ease"
            step="1"
          >
            <DashboardAdderStep1 ref="step1" />
          </v-stepper-content>
          <v-stepper-content
            class="stepper-content"
            step="2"
          >
            <DashboardAdderStep2
              ref="step2"
              :project="project"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-card-actions>
        <v-row
          class="px-8 py-4"
          no-gutters
          justify="space-between"
        >
          <v-col cols="auto">
            <v-btn
              class="btn-cancel"
              :disabled="isSending"
              @click="cancel"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="step > 1"
              class="mr-4"
              color="primary"
              :disabled="isSending"
              @click="prevStep"
            >
              {{ $t('prev_step') }}
            </v-btn>
            <v-btn
              v-if="step < totalStep"
              color="primary"
              :disabled="isSending"
              @click="nextStep"
            >
              {{ $t('next_step') }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              :loading="isSending"
              @click="submit"
            >
              <template v-if="isEdit">
                <v-icon class="mr-2">
                  mdi-content-save
                </v-icon>
                <span>{{ $t('save') }}</span>
              </template>
              <span v-else>{{ $t('create') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DashboardAdderStep1 from './DashboardAdderStep1'
import DashboardAdderStep2 from './DashboardAdderStep2'

import { MODEL } from '@/store/modules/files/files'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DashboardAdder',

  components: {
    DashboardAdderStep1,
    DashboardAdderStep2
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    add: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    step: 1,
    totalStep: 2,
    isSending: false
  }),

  computed: {
    ...mapState({
      formData: state => state.dashboards.form.formData
    }),
    ...mapGetters({
      isEdit: 'dashboards/form/editModel',
      getProjectByRoute: 'projects/getProjectByRoute',
      isLoadingFolders: 'files/isLoadingFolders'
    }),

    dialog: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },

    stepRefs() {
      return Array.from({ length: this.totalStep }, (_, i) => `step${i + 1}`)
    },
    project() {
      return this.getProjectByRoute(this.$route) || {} // project || undefined
    }
  },

  watch: {
    dialog: {
      handler(newVal) {
        if (!newVal) {
          this.init()
        }

        if (newVal) {
          this.$store.dispatch('files/setModel', MODEL.DASHBOARD)

          const fetchFolders = this.isLoadingFolders
            ? Promise.resolve()
            : this.$store.dispatch('files/fetchFileNodes', {
              project: this.project
            })
          fetchFolders
            .then(() => {
              const fileUuid = this.formData.maplayer_resource_uuid
              if (this.isEdit && fileUuid) {
                this.$store.dispatch('files/fetchFileSubTree', {
                  project: this.project,
                  fileUuid
                })
              }
            })
        }
      },
      immediate: true
    }
  },

  methods: {
    init() {
      this.step = 1
      this.$store.dispatch('dashboards/form/init')
      this.$store.dispatch('files/init')
      this.resetValidation()
    },
    resetValidation() {
      this.stepRefs.map(ref => {
        return this.$refs?.[ref]?.resetValidation?.()
      })
    },
    validateAll() {
      return Promise.all(this.stepRefs.map(ref => this.formValidate(ref))).then(
        results => {
          const firstErrorStep = results.findIndex(v => !v) + 1
          if (firstErrorStep) {
            this.step = firstErrorStep
          }

          return results.every(Boolean)
        }
      )
    },
    async formValidate(ref) {
      const funValidate = this.$refs?.[ref]?.validate

      if (typeof funValidate !== 'function') {
        return true
      }

      return funValidate()
    },
    cancel() {
      this.dialog = false

      this.init()
    },
    prevStep() {
      this.step -= 1
    },
    async nextStep() {
      const ref = `step${this.step}`
      const isValid = await this.formValidate(ref)

      if (!isValid) return

      this.step += 1
    },
    fetchDashboards(loading = true) {
      return this.$store.dispatch('dashboards/fetchDashboards', {
        project: this.project,
        loading
      })
    },
    async submit() {
      if (!(await this.validateAll())) return

      this.isSending = true

      const error = await this.fetchDashboards(false).catch(error => error)
      if (error) {
        this.$store.dispatch('snackbar/showError', {
          content: error
        })

        this.isSending = false
        return
      }

      if (!(await this.validateAll())) {
        this.isSending = false
        return
      }

      this.$store
        .dispatch('dashboards/form/submit', {
          project: this.project
        })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.isEdit
              ? this.$t('success_edited')
              : this.$t('success_created')
          })

          this.$emit('successAdded')

          this.cancel()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-adder-stepper {
  height: calc(100% - 84px);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;

  ::v-deep .step-title {
    font-size: 1.375rem;
  }

  .v-stepper__items {
    height: 100%;

    .stepper-content {
      padding: 0;
      height: 100%;

      ::v-deep .v-stepper__wrapper {
        height: 100%;
      }
    }
  }
}

.v-card__actions {
  & .v-btn.v-btn {
    padding: 4px 1.875rem;
    font-size: 1.25rem;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    letter-spacing: normal;

    &.btn-cancel {
      color: #343843;
      border: solid 1px #ddd;
    }
  }
}
</style>
