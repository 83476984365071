<template>
  <v-card
    class="filemgr-card"
    elevation="0"
    color="transparent"
  >
    <v-row
      class="panel-row flex-nowrap py-3"
      no-gutters
    >
      <v-col
        class="panel-col"
        style="flex-basis: 500px;"
      >
        <FileTree
          readonly
          style="border: none;"
        />
      </v-col>
      <v-col
        class="panel-col ml-3"
        style="flex-basis: 780px;"
      >
        <FileViewer
          readonly
          style="border: none;"
        />
      </v-col>
      <v-col
        class="
          panel-col
          ml-3
        "
        style="flex-basis: 600px; position: relative;"
      >
        <FileInfo
          readonly
          style="border: none;"
        />
        <v-btn
          v-if="selectedFileNode"
          class="join-btn"
          :color="isJoined ? 'error' : 'primary'"
          outlined
          rounded
          x-large
          @click="toggleJoinDashboard()"
        >
          <span v-if="isJoined">
            {{ $t('page.files.cancel_dashboard_bound') }}
          </span>
          <span v-else>
            {{ $t('page.files.bind_dashboard') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import FileTree from './Panels/FileTree'
import FileViewer from './Panels/FileViewer'
import FileInfo from './Panels/FileInfo'

import { FILE_TYPE } from '@/models/utils'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FileManagerDashboard',

  components: {
    FileTree,
    FileViewer,
    FileInfo
  },

  computed: {
    ...mapState({
      activeFileNode: state => state.files.activeFileNode
    }),
    ...mapGetters({
      isFileJoined: 'dashboards/form/isFileJoined'
    }),

    selectedFileNode() {
      if (this.activeFileNode?.type !== FILE_TYPE.FILE) {
        return null
      }

      return this.activeFileNode
    },
    isJoined() {
      return this.isFileJoined(this.selectedFileNode)
    }
  },

  methods: {
    toggleJoinDashboard() {
      if (!this.selectedFileNode) {
        return
      }

      if (this.isJoined) {
        this.$store.dispatch('dashboards/form/setFormData', {
          maplayer_resource_uuid: ''
        })

        return
      }

      this.$store.dispatch('dashboards/form/setFormData', {
        maplayer_resource_uuid: this.selectedFileNode.uuid
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.filemgr-card {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.panel-row {
  max-height: 100%;
  flex-grow: 1;
}

.panel-col {
  overflow-x: auto;
  height: 100%;
  border: solid 0.8px white;
  border-radius: 0;
}

.join-btn {
  position: absolute;
  right: 40px;
  bottom: 40px;
  height: 48px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2) !important;
}
</style>
